import React, { useState, useEffect } from "react";
import { Dialog, Slide } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import usePostDataToServer from "../../hooks/usePostDataToServer";
import CloseIcon from "@mui/icons-material/Close";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import RemarkModal from "./RemarkModal";
import { useParams } from "react-router-dom";
import { checkIfEmpty, checkIfNotEmpty } from "../../utility";
import Spinner from "../../components/spinner";
import AlertContext from "../../context/alerts";
import { useContext } from "react";
import UpdateStats from "./UpdateStats";

function BackDateTableModal({ onClose, open, modalData, data, yearId, apiData, yearData }) {
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
    const [editStats, setEditStats] = useState()
    const [newData, setNewData] = useState()

    useEffect(() => {
        if (
            checkIfNotEmpty(yearId?.year_id) &&
            checkIfNotEmpty(data?.data?.data)
        ) {
            setNewData(data?.data)
        }
    }, [yearId?.year_id, data?.data]);

    const handleClearAndCloseForm = (e) => {
        e.preventDefault();
        onClose();
    };

    const ButtonOpenEditStats = (e, data) => {
        e.preventDefault()
        setEditStats(data)
        setIsOpenEditDialog(true)
    }
    return (<Dialog PaperProps={{ sx: { top: "-23%" } }} maxWidth={"lg"} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
        <div className="bg-white w-full p-5">
            <div className="modal-head flex items-center justify-between">
                <div className="mt-1.5">
                    <h1 className="heading capitalize text-3xl">Categories</h1>
                </div>
                <div>
                    <div onClick={onClose}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <form>
                <>
                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Sr.no
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Product Name
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {checkIfEmpty(newData?.data) ? <div>
                                    <span>No Data Available</span>
                                </div> : checkIfNotEmpty(newData?.data) && newData?.data[modalData]?.map((tabledata, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td className="border-dashed border-t border-gray-200">
                                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm ">
                                                    {idx + 1}
                                                </span>
                                            </td>
                                            <td className="border-dashed border-t border-gray-200">
                                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                    {tabledata?.product_name}
                                                </span>
                                            </td>
                                            <td className="border-dashed border-t border-gray-200">
                                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                    <button
                                                        onClick={(e) => ButtonOpenEditStats(e, tabledata)}
                                                        className="rounded-full w-28 h-7 font-normal text-white bg-[color:var(--primaryRed)]">
                                                        Edit Stats
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            </form>
        </div>
        {isOpenEditDialog && <UpdateStats data={editStats} yearData={yearId} open={isOpenEditDialog} onCloseUpdateStats={() => setIsOpenEditDialog(false)} />}
    </Dialog>);
}
export default BackDateTableModal;