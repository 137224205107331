import { useEffect, useRef, useState } from "react";
import { displayVendorDetails } from "../apis/vendorListApi";
import { checkIfNotEmpty } from "../utility";

const useGetVendorDropdown = ({ productFormData } = {}) => {
  const [vendorValue, setVendorValue] = useState([]);
  const [vendorPageNo, setVendorPageNo] = useState(0);
  const [previousStateVendor, setPreviousStateVendor] = useState("");

  const initialLoadForEffect = useRef(true);
  const initialLoadVendor = useRef(false);

  const formatData = (arr, valueName, labelName) => {
    let newarr = [];
    for (let i = 0; i < arr?.length; i++) {
      newarr.push({
        value: arr[i]?.[valueName],
        label: arr[i]?.[labelName],
        ...arr[i],
      });
    }
    return newarr;
  };

  const getVendorName = async () => {
    const res = await displayVendorDetails();
    const res2 = formatData(res.data.data, "id", "name");
    setVendorValue(res2);
  };

  useEffect(() => {
    if (initialLoadForEffect.current) {
      getVendorName();
      initialLoadForEffect.current = false;
    }
  }, []);

  const loadVendorNames = (search, prevOptions) => {
    const formatDataFn = (resp) => {
      return formatData(resp, "id", "name");
    };

    return loadOptionsVendor(
      search,
      prevOptions,
      initialLoadVendor,
      vendorValue,
      setVendorValue,
      vendorPageNo,
      setVendorPageNo,
      formatDataFn
    );
  };

  const getVendorNameFn = () => {
    let name = vendorValue?.find((el) => {
      return parseInt(el.product_id) == parseInt(productFormData?.product_id);
    });

    if (checkIfNotEmpty(name)) {
      return name;
    } else {
      return {
        id: productFormData?.vendor_id,
        value: productFormData?.vendor_id,
        vendor_name: productFormData?.vendor_name,
        label: productFormData?.vendor_name,
      };
    }
  };

  const loadOptionsVendor = async (
    search,
    prevOptions,
    initialLoad,
    state,
    setState,
    pageNo,
    setPageNo,
    formatDataFn
  ) => {
    try {
      if (!initialLoad.current && !search) {
        initialLoad.current = true;
        return {
          options: state,
          hasMore: true,
        };
      } else {
        let newPage;
        if (checkIfNotEmpty(search) && previousStateVendor !== search) {
          newPage = 0;
          setPageNo(newPage);
        } else {
          // newPage = pageNo;
          newPage = pageNo + 1;
          setPageNo(newPage);
        }

        let res = await displayVendorDetails(newPage, search);
        newPage = pageNo + 1;
        setPageNo(newPage);

        const data = state.concat(res?.data?.data);
        setState(data);
        let temps = res.data.data;

        let hasMore = true;
        if (res?.data?.data?.length == 0) {
          hasMore = false;
        }
        let arr = formatDataFn(res?.data?.data);
        setPreviousStateVendor(search);

        return {
          options: arr,
          hasMore: hasMore,
        };
      }
    } catch (err) {
      // console.log(err)
    }
  };

  // Ends here
  return { vendorValue, loadVendorNames, getVendorNameFn };
};

export default useGetVendorDropdown;
