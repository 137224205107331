import React, { useState, useEffect, usePara, useContext } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/layout";
import useGetDataFromServer from "../../hooks/useGetDataFromServer";
import EditIcon from "@mui/icons-material/Edit";
import Errors from "../errors";
import RoomCategoryModal from "./RoomCategoryModal";
import { checkIfEmpty, checkIfNotEmpty } from "../../utility";
import CreateStats from "./CreateStats";
import UpdateStats from "./UpdateStats";
import BackDateTableModal from "./BackDateTableModal";
import Spinner from "../../components/spinner";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import useDownloadXlsxFile from "../../hooks/useDownloadXlsxFile";
import ConfirmationModal from "../productAssets/ConfirmationModal";
import DeleteItem from "../../components/DeleteItem/DeleteItem";
import AlertContext from "../../context/alerts";
import Pagination from "../../components/pagination";

function RoomInventoryData() {
    const { roomname, roomid } = useParams();

    const getYearList = useGetDataFromServer("getYearList");
    const listCategory = useGetDataFromServer("listCategory");

    const [yearData, setYearData] = useState({
        year_id: "",
        room_id: roomid,
        category_id: "",
        limit: 10,
        pageNo: 0,
    });
    const [yearId, setYearId] = useState();
    const [modalData, setModalData] = useState({});
    const [newData, setNewData] = useState()
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [apiData, setApiData] = useState();
    const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [submitData, setSubmitData] = useState();
    const [editStats, setEditStats] = useState()

    const { sendAlert } = useContext(AlertContext)

    // && !!yearData?.category_id

    const { handleGetData, setGetListParams, paramsObject } = useGetDataFromServer("getClassRoomStats", yearData, !!yearData?.year_id );

    const openModal = (e, roomData) => {
        e.preventDefault();
        setIsDialogOpen(true);
        setModalData(roomData);
        setApiData(handleGetData.data.data);
    };

    const handleInputs = (e) => {
        let value = e.target.value;
        let data = {
            ...yearData,
            [e.target.name]: value,
        };
        setYearData(data);
        setYearId(data);
        setGetListParams(data);
    };

    const handleOpenCreate = () => {
        setIsCreateDialogOpen(true);
    };
    const handleEditOpenModal = (e, roomData) => {
        e.preventDefault()
        setModalData(roomData);
        setApiData(handleGetData.data.data);
        setIsOpenEditDialog(true)
    }
    const handleCloseDialog = () => {
        setIsCreateDialogOpen(false);
        setIsOpenEditDialog(false)
    };
    const { reportDownload } = useDownloadXlsxFile({ url: "/getRoomInventory", onError: (data) => sendAlert(data, "WARNING") })
    const handleDownloadReport = (e) => {
        e.preventDefault()
        const el = document.getElementById('year_id_' + yearId?.year_id)
        let data = {
            room_id: roomid,
            year_id: yearId?.year_id,
            filename: el.textContent + "_Room_Stats.xlsx"
        }
        reportDownload.mutate(data)
    }


    const saveData = () => {
        let data = {
            year_id: yearData?.year_id,
            category_id: yearData?.category_id,
            room_id: roomid,
            asset_data: JSON.stringify(handleGetData?.data?.data)
        }
        setSubmitData(data)
        setOpenConfirm(true)
    }


    const [deleteData, setDeleteData] = useState()
    const deleteAssetData = () => {
        let data = {
            year_id: yearData?.year_id,
            category_id: yearData?.category_id,
            room_id: roomid
        }
        setDeleteData(data)
        setOpenDeleteModal(true)
    }

    const onDeleteSuccess = (data) => {
        sendAlert(data?.message, "SUCCESS")
    }

    const onSubmitSuccess = (data) => {
        sendAlert(data?.message, "SUCCESS")
    }

    const onDeleteError = (data) => {
        sendAlert(data?.response?.data?.message, "ERROR")
    }

    const onSubmitError = (data) => {
        sendAlert(data?.response?.data?.message, "ERROR")
    }

    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }

    const ButtonOpenEditStats = (e, data, stats_id) => {
        e.preventDefault()
        setEditStats(handleGetData.data.data.find(x => x.stats_id === stats_id))
        setIsOpenEditDialog(true)
    }

    return (
        <Layout>
            <>
                {reportDownload.isLoading ? <div>
                    <Spinner className="flex justify-end mt-5" />
                </div> : checkIfNotEmpty(yearData?.year_id) ? <div className="flex justify-end mt-5">
                    <button onClick={(e) => handleDownloadReport(e)} className="bg-[color:var(--primaryRed)] text-white h-10 w-44 rounded-lg">
                        <FileDownloadIcon /> <span>Report</span>
                    </button>
                </div> : ""}
                <div className='flex space-x-20 justify-center'>
                    <div className="flex flex-col items-center mt-6">
                        <label className="block text-xl lato mb-5">
                            Please Select a Year from the Dropdown
                        </label>
                        <select required onChange={handleInputs} name="year_id" className="border-2 border-grey w-96 h-12 rounded-md">
                            <option value="">--Select Year--</option>
                            {getYearList?.handleGetData?.data?.data?.map((categoryDrop, id) => {
                                return (
                                    <option id={'year_id_' + categoryDrop?.year_id} value={categoryDrop?.year_id}>
                                        {categoryDrop?.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {/* <div className="flex flex-col items-center mt-5">
                        <label className="block text-xl lato mb-5">Please Select a Category from the Dropdown</label>
                        <select onChange={handleInputs} name="category_id"
                            className="border-2 border-grey w-96 h-12 rounded-md">
                            <option value="">--Select Type--</option>
                            {listCategory?.handleGetData?.data?.data?.map((categoryDrop, id) => { return <option value={categoryDrop?.id}>{categoryDrop?.category_name}</option> })}
                        </select>
                    </div> */}
                </div>
                {/* && yearData?.category_id */}
                {handleGetData.isLoading && checkIfNotEmpty(yearData?.year_id) ?
                    <Spinner /> : handleGetData?.data?.data?.message?.startsWith("No Data")
                        ? <div className="flex items-center justify-center h-96">
                            <p className="text-lg font-semibold">No Data Available</p>
                        </div>
                        : handleGetData?.data?.create_stats == "true" ? (
                            <>
                                <div className="flex justify-end">
                                    <button className="btn3 text-white" onClick={handleOpenCreate}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                        <span className="text-sm">Create Stats</span>
                                    </button>
                                </div>
                                <div>
                                    {checkIfNotEmpty(handleGetData?.data?.data) ? (
                                        <>
                                            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Sr.no
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Product Name
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-4 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Current Quantity
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Initial Quantity
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Final Quantity
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Category Name
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {handleGetData?.data.data.map((roomData, idx) => {
                                                            const { product_name, final_quantity, current_quantity, initial_quantity, stats_id, category_name } = roomData
                                                            return <tr key={idx}>
                                                                <td className="border-dashed border-t border-gray-200 userId">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                                        {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{product_name}</span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{current_quantity}</span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{initial_quantity}</span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{final_quantity}</span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{category_name}</span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm cursor-pointer">
                                                                        <span data-id='1'><EditIcon onClick={(e) => ButtonOpenEditStats(e, roomData, stats_id)} /></span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* {handleGetData?.data?.create_stats == "false" ? <div className='flex justify-end mt-10'>
                                                {handleGetData.data.isSet === true ? <button className="btn3 text-white bg-[color:var(--primaryRed)]" onClick={deleteAssetData}>
                                                    <span className="text-sm">Delete</span>
                                                </button> : <button className="btn3 text-white" onClick={saveData}>
                                                    <span className="text-sm">Submit</span>
                                                </button>}
                                            </div> : ""} */}
                                        </>
                                    ) : ("")}
                                </div>
                                {isOpenEditDialog && (
                                    <UpdateStats
                                        data={editStats}
                                        modalData={modalData}
                                        open={isOpenEditDialog}
                                        yearData={yearData}
                                        onCloseUpdateStats={() => setIsOpenEditDialog(false)}
                                    />
                                )}
                            </>
                        ) : handleGetData?.data?.data <= 0 ? <div className="flex items-center justify-center h-96">
                            <p className="text-lg font-semibold">No Data Available</p>
                        </div> : checkIfNotEmpty(handleGetData?.data?.data) ? (
                            <>
                                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Sr.no
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Product Name
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Current Quantity
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Initial Quantity
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Category Name
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Final Quantity
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {handleGetData?.data?.data?.map((roomData, idx) => {
                                                const { product_name, final_quantity, current_quantity, initial_quantity, category_name } = roomData
                                                return <tr key={idx}>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                            {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{product_name}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{current_quantity}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{initial_quantity}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{category_name}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{final_quantity}</span>
                                                    </td>
                                                    
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {handleGetData?.data?.current_year == 'true' ? <div className='flex justify-end mt-10'>
                                    {handleGetData.data.isSet === true ? <button className="btn3 text-white bg-[color:var(--primaryRed)]" onClick={deleteAssetData}>
                                        <span className="text-sm">Delete</span>
                                    </button> : <button className="btn3 text-white" onClick={saveData}>
                                        <span className="text-sm">Submit</span>
                                    </button>}
                                </div> : ""}
                            </>
                        ) : (
                            ""
                        )}
                {/* {checkIfNotEmpty(yearData?.year_id ) && checkIfNotEmpty(handleGetData?.data?.data) ?
                    <div className=" my-7">
                        <Pagination currentPage={paramsObject.pageNo} pageCount={handleGetData?.data?.count} limit={paramsObject.limit} onPageChange={handlePageClick} />
                    </div> : ""} */}
            </>
            {isDialogOpen && (
                <RoomCategoryModal
                    apiData={apiData}
                    yearId={yearId}
                    data={handleGetData}
                    modalData={modalData}
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                />
            )}
            <CreateStats yearData={yearId} open={isCreatDialogOpen} onClose={handleCloseDialog} />

            {openDeleteModal && <DeleteItem onDelete={onDeleteSuccess} onError={onDeleteError} open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} multiParam={deleteData} url={"deleteClassRoomStats"} queryKey={"getClassRoomStats"} />}
            {openConfirm && <ConfirmationModal content={"Are you sure you want to submit"} multiParam={submitData} onSubmit={onSubmitSuccess} onError={onSubmitError} open={openConfirm} onClose={() => setOpenConfirm(false)} url={'/setFinalQuantity'} queryKey={"getClassRoomStats"} />}
        </Layout>
    );
}

export default RoomInventoryData;

// {Object.keys(handleGetData.data.data).map((roomData, idx) => {
//     return <div>

//         </div>
// })}

// {Object.values(handleGetData.data.data).map((values, id) => {
//     return <div>
//         <tbody>
//             <td className="border-dashed border-t border-gray-200 ">
//                 <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{values?.room_name}</span>
//             </td>
//         </tbody>
//     </div>
// })}

// {getYearList?.handleGetData?.data?.data?.current_year === "true" ? (
//     <div className="flex justify-center">
//       <p>Current Year</p>
//     </div>
//   ) : checkIfNotEmpty(handleGetData?.data?.data) ? (
//     <div className="grid grid-cols-3 px-5">
//       {Object.keys(handleGetData?.data?.data).map((roomData, idx) => {
//         return (
//           <div
//             onClick={(e) => openModal(e, roomData)}
//             className="relative cursor-pointer h-48 w-96 bg-white shadow-lg rounded px-8 mt-12 p-4 mb-4 flex justify-center"
//           >
//             <div className="mt-16">
//               <p className="text-xl font-medium">{roomData}</p>
//             </div>
//             <div className="">
//               <EditIcon className="absolute right-0 bottom-0" />
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   ) : (
//     ""
//   )}
