import { Modal } from '@mui/material'
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
// import product from '../../assets/images/product.png'

function ProductModal({open,handleClose}) {
    return (
        <>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className='modal-container w-[960px] mt-12 mx-auto bg-white'>
                    <div className='px-4'>
                        <div className="head py-4 border-b border-gray-300">
                            <div className='flex items-center justify-between'>
                                <h1 className=' text-4xl font-semibold text-gray-700'>Product</h1>
                                <ClearSharpIcon className='cursor-pointer'onClick={handleClose}/>
                            </div>
                        </div>
                        <div className="body py-4">
                            <div className='w-full h-96 overflow-hidden'>
                                {/* <img src={product} alt="product" className='w-full h-full object-contain'/> */}
                            </div>
                        </div>
                        <div className="foot">

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ProductModal