import React, { useContext, useState } from 'react'
import ProductLayout from '.'
import Spinner from '../../components/spinner'
import Errors from '../errors'
import AddSingleProduct from './AddSingleProduct'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ProductModal from './ProductModal'
import EditSingleProduct from './EditSingleProduct'
import useGetDataFromServer from '../../hooks/useGetDataFromServer'
import Pagination from '../../components/pagination'
import ProductPreview from './ProductPreview'
import { checkIfEmpty, getDateFromTimestamp } from '../../utility'
import Layout from '../../components/layout'
import DeleteProduct from './DeleteProduct'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteItem from '../../components/DeleteItem/DeleteItem'
import QrCodeIcon from '@mui/icons-material/QrCode';
import ProductQrModal from '../../components/ProductQrModal/ProductQrModal'
import { Link } from 'react-router-dom'
import AlertContext from '../../context/alerts'
import deBounce from '../../hooks/useDebounce'


function SingleProduct() {
  const initialData = {
    pageNo: 0,
    limit: 10,
    searchQuery: '',
  }
  const [singleData, setSingleData] = useState({})
  const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)

  const [openQrModal, setOpenQrModal] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [open, setOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)

  const [yearId, setYearId] = useState('')

  const [isDeleted, setIsDeleted] = useState(false)

  const { sendAlert } = useContext(AlertContext)


  const handleDeletedSuccess = (data) => {
    setIsDeleted(true)
    setOpen(true)
    sendAlert(data?.message, "SUCCESS")
  }

  const handleCreatedSuccess = () => {
    setCreateOpen(true)
  }

  const handleEditSuccess = () => {
    setEditOpen(true)
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleOpenCreateProduct = () => {
    setIsCreateDialogOpen(true)
  }
  const handleCloseDialog = () => {
    setIsCreateDialogOpen(false)
  }

  const handleCloseQrModal = () => {
    setOpenQrModal(false)
  }

  const handleOpenQrModal = (id) => {
    setSingleData(handleGetData.data.data.find(x => x.product_id === id))
    setOpenQrModal(true)
  }
  const [openProductModal, setProductModal] = useState(false)
  const handleProductModal = () => {
    setProductModal(true)
  }
  const [openEditModal, setEditModal] = useState(false)
  const handleProductEdit = (id) => {
    setSingleData(handleGetData.data.data.find(x => x.product_id === id))
    setEditModal(true)
  }
  const handleCategoryDelete = (id) => {
    setSingleData(handleGetData.data.data.find(x => x.product_id === id))
    setOpenDeleteModal(true)
    setYearId(id)
  }
  const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer('listProduct', initialData)
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      pageNo: val.selected
    }
    setGetListParams(data)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setCreateOpen(false)
    setEditOpen(false)
  }
  const handleSearch = (e) => {
    let value = e.target.value
    let data = {
      ...paramsObject,
      [e.target.name]: value
    }
    deBounce(setGetListParams, data)
    // setGetListParams(data)
  }

  const onDeleteError = (data) => {
    sendAlert(data?.response?.data?.message, "ERROR")
  }
  return (
    <>
      <Layout>
        <div className="py-4 border-t border-gray-300">
          <div>
            <h1 className='text-center heading capitalize text-3xl'>Products</h1>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <input type="text" className="input2" name="searchQuery" placeholder="Search Product..." value={paramsObject.search_query} onChange={handleSearch}></input>
            </div>
            <button className="btn3 text-white" onClick={handleOpenCreateProduct}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              <span className="text-sm">Add Product</span>
            </button>
          </div>
        </div>
        {handleGetData.isLoading ? <Spinner />
          : handleGetData.isError ? <Errors errorObject={handleGetData?.error} inline />
            : handleGetData?.data?.data <= 0 ? <div className="flex items-center justify-center h-96">
              <p className="text-lg font-semibold">No Data Available</p>
            </div> : <><div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
              <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                <thead>
                  <tr className="text-left">
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Sr.no
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Product Name
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Action
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                    </th>
                  </tr>
                </thead>
                <tbody>
                  {handleGetData.data?.data?.map((list, listIdx) => {
                    const { product_name, product_id, created_on, disabled_no, updated_on, active } = list
                    return <tr key={listIdx}>
                      <td className="border-dashed border-t border-gray-200 userId">
                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                          {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (listIdx + 1))}
                        </span>
                      </td>
                      <td className="border-dashed border-t border-gray-200 firstName">
                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{product_name}</span>
                      </td>
                      <td className="border-dashed border-t border-gray-200 firstName">
                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                          <span data-id='1' onClick={() => handleProductEdit(product_id)}><EditIcon /></span>
                          <DeleteIcon onClick={() => handleCategoryDelete(product_id)} />
                          <span onClick={() => handleOpenQrModal(product_id)}><QrCodeIcon /></span>
                        </span>
                      </td>
                      <td className="border-dashed border-t border-gray-200 firstName">
                        <Link to={"/deductproduct/" + product_id} className="bg-[color:var(--primaryRed)] text-white px-6 py-1 rounded-lg">View Details</Link>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
              <div className="my-7">
                <Pagination
                  currentPage={paramsObject.pageNo}
                  pageCount={handleGetData?.data.total_count}
                  limit={paramsObject.limit}
                  onPageChange={handlePageClick} />
              </div>
              {/* <Snackbar open={open} onClose={handleClose} autoHideDuration={5000}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Deleted Successfully
                </Alert>
              </Snackbar> */}
              <Snackbar open={createOpen} onClose={handleClose} autoHideDuration={5000}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Created Successfully
                </Alert>
              </Snackbar>
              <Snackbar open={editOpen} onClose={handleClose} autoHideDuration={5000}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Edited Successfully
                </Alert>
              </Snackbar>
            </>
        }

      </Layout>
      <AddSingleProduct open={isCreatDialogOpen} onClose={handleCloseDialog} onCreate={handleCreatedSuccess} />
      {openEditModal && <EditSingleProduct onEdit={handleEditSuccess} open={openEditModal} singleData={singleData} onClose={() => setEditModal(false)} />}
      {openDeleteModal && <DeleteItem onError={onDeleteError} onDelete={handleDeletedSuccess} open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} id={yearId} url={"/deleteProduct"} queryKey={"listProduct"} />}

      {openQrModal && <ProductQrModal open={openQrModal} onClose={handleCloseQrModal} singleData={singleData} />}
    </>
  )
}

export default SingleProduct