import React, { useState, useEffect, useContext } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import FormElement from '../form/FormElement';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../spinner';
import AlertContext from '../../context/alerts';

function AddRoomType({ open, onClose, onCreate }) {
    const { sendAlert } = useContext(AlertContext)
    const initialData = {
        typeName: ''
    }
    const [roomTypeData, setRoomTypeData] = useState(initialData);
    const { setDataToServer, error } = usePostDataToServer("/createRoomType", "listingRoomType", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const handleInputs = (e) => {
        let value = e.target.value
        let data = {
            ...roomTypeData,
            [e.target.name]: value
        }
        setRoomTypeData(data)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        setDataToServer.mutate(roomTypeData)
    }
    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }

    return (<Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
        <div className='bg-white w-full px-5'>
            <div className='modal-head flex items-center justify-between'>
                <div className='mt-1.5'>
                    <h1 className='heading capitalize text-2xl'>Add Room Type</h1>
                </div>
                <div>
                    <div onClick={onClose}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <form onSubmit={(e) => handleSubmitForm(e)} className="py-7">
                <div className='flex justify-center space-x-48'>
                    <FormElement
                        label={'Room Type'}
                        onChange={handleInputs}
                        placeholder={'e.g. Classroom'}
                        element={'input'}
                        name={'typeName'}
                        autocomplete={"off"}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> :
                    <div className="flex justify-center mt-5">
                        <button type='submit' className='text-white btn3'>Create</button>
                    </div>}
                {error && <small className="text-red-700 font-semibold mt-1">{setDataToServer?.error?.response?.data?.message}</small>}
            </form>
        </div>
    </Dialog>
    )
}
export default AddRoomType;