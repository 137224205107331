import React from 'react'
import { Dialog, Slide } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import FormElement from '../../components/form/FormElement';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import Spinner from '../../components/spinner';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditCustomers({ open,singleData, onClose, customerType, fields, url }) {
    const [customerFormData, setCustomerFormData] = useState(singleData)
    const { setDataToServer } = usePostDataToServer(url)
    const handleCustomerInputs = (e) => {
        let value = e.target.value
        let data = {
            ...customerFormData,
            [e.target.name]: value
        }
        setCustomerFormData(data)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        setDataToServer.mutate(customerFormData)
    }
    const handleClearAndCloseForm = () => {
        setCustomerFormData(singleData)
        onClose()
    }
    return (
        <Dialog fullScreen fullWidth={true} open={open} onClose={handleClearAndCloseForm} TransitionComponent={Transition}>
            <div className='container_xxl'>
                <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={handleClearAndCloseForm}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading capitalize'>Edit {customerType}</h1>
                    </div>
                </div>
                <div className='form-body py-8'>
                    <form className='' autoComplete='off' onSubmit={handleSubmitForm}>
                        <div className='grid grid-cols-3 gap-4'>
                            {fields?.map((field, idx) => {
                                const { name } = field
                                return <FormElement
                                    key={idx}
                                    value={customerFormData[name]}
                                    onChange={handleCustomerInputs}
                                    {...field}
                                />
                            })}
                        </div>
                        <div className='text-center mt-6'>
                            {setDataToServer.isLoading ? <Spinner />
                                : <button className="btn3 mx-auto"><span>Save</span></button>}
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default EditCustomers