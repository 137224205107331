import React from 'react'
import { Button, Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useDeleteDataFromServer } from '../../hooks/useDeleteItem';
import { checkIfNotEmpty } from '../../utility';
import Spinner from '../spinner';

function DeleteItem({ open, onClose, id, onDelete, url, queryKey, multiParam, onError }) {
    const { setDeleteData } = useDeleteDataFromServer(url, queryKey, {
        onSuccesCB: (data) => {
            onClose()
            onDelete(data)
        },
        onErrorCB: (err) => {
            onError(err)
            // console.log(err);
        }
    })
    const handleDeleteCategory = (e) => {
        e.preventDefault()
        if (checkIfNotEmpty(id)) {
            let data = {
                id
            }
            setDeleteData.mutate(data)
        } else if (checkIfNotEmpty(multiParam)) {
            setDeleteData.mutate(multiParam)
        }

    }
    return (
        <>
            <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={onClose}>
                <div className=' bg-white w-full px-5'>
                    <div className='modal-head flex items-center justify-end'>
                        <div onClick={onClose}>
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <form className=' space-y-4' onSubmit={handleDeleteCategory}>
                        <div className=' space-y-7'>
                            <div>
                                <h1 className=' text-2xl text-red-700 font-semibold text-center'>Are You Sure You Want To Delete?</h1>
                            </div>
                        </div>
                        {setDeleteData.isLoading ?
                            <Spinner /> : <div className=' space-x-4 text-center pb-5'>
                                <Button type='submit' variant="contained" color={`error`}>Delete</Button>
                                <Button variant="outlined" color='info' onClick={onClose}>Close</Button>
                            </div>}
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default DeleteItem