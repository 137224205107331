const IS_REQUIRED = true
export const CUSTOMER_FIELDS = [
    {
        name: 'name',
        placeholder: 'Enter Customer Name',
        label: 'Customer Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'phone_number',
        placeholder: 'Enter Phone Number',
        label: 'Phone Number',
        type: 'tel',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'email',
        placeholder: 'Enter Email',
        label: 'Email',
        type: 'email',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'business_name',
        placeholder: 'Enter Business Name',
        label: 'Business Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'business_number',
        placeholder: 'Enter Business Number',
        label: 'Business Number',
        type: 'tel',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'business_email',
        placeholder: 'Enter Business Email',
        label: 'Business Email',
        type: 'email',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'address_one',
        placeholder: 'Enter Address Line 1',
        label: 'Address Line 1',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'address_two',
        placeholder: 'Enter Address Line 2',
        label: 'Address Line 2',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'address_three',
        placeholder: 'Enter Address Line 3',
        label: 'Address Line 3',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'state',
        placeholder: 'Enter State',
        label: 'State',
        type: 'text',
        element: 'select',
        required: IS_REQUIRED,
        optionValue:[
            {name:'karanataka',value:'karanataka'}
        ],
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'city',
        placeholder: 'Enter city',
        label: 'city',
        type: 'text',
        element: 'select',
        required: IS_REQUIRED,
        optionValue:[
            {name:'bangalore',value:'bangalore'}
        ],
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'gst_id',
        placeholder: 'Enter GST ID',
        label: 'GST ID',
        type: 'tel',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'email_2',
        placeholder: 'Enter Email 2',
        label: 'Email 2',
        type: 'email',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]