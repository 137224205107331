import React, { useContext } from 'react'
import { Button, Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../components/spinner';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import { checkIfNotEmpty } from '../../utility';

function ConfirmationModal({ onClose, open, url, queryKey, multiParam, id, onSubmit, onError, content }) {

    const { setDataToServer } = usePostDataToServer(url, queryKey, {
        onSuccessCb: (data) => {
            onClose()
            onSubmit(data)

        },
        onErrorCb: (err) => {
            // console.log('error', err)
            onError(err)
        }
    })

    const handleSubmitData = (e) => {
        e.preventDefault()
        if (checkIfNotEmpty(id)) {
            let data = {
                id
            }
            setDataToServer.mutate(data)
        } else if (checkIfNotEmpty(multiParam)) {
            setDataToServer.mutate(multiParam)
        }
    }

    return (
        <>
            <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={onClose}>
                <div className=' bg-white w-full px-5'>
                    <div className='modal-head flex items-center justify-end'>
                        <div onClick={onClose}>
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <form className='space-y-12' onSubmit={handleSubmitData}>
                        <div className='space-y-7'>
                            <div>
                                <h1 className='text-2xl text-red-700 font-semibold text-center'>{content}</h1>
                            </div>
                        </div>
                        {setDataToServer.isLoading ?
                            <Spinner /> : <div className='mt-10 space-x-4 text-center pb-5'>
                                <Button type='submit' variant="contained" className='bg-[color:var(--primaryBlue)]'>Submit</Button>
                                <Button variant="outlined" color='info' onClick={onClose}>Close</Button>
                            </div>}
                    </form>
                </div>
            </Dialog>
        </>
    );
}

export default ConfirmationModal;