import React, { useState } from 'react'
import CreateForm from './CreateForm'
import Spinner from '../../components/spinner'
import Errors from '../errors'
import useGetDataFromServer from '../../hooks/useGetDataFromServer'
import Pagination from '../../components/pagination'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Purchase() {
    const initialFilterData = {
        page_no: 0,
        limit: 20,
        search_query: '',
        type:'all'
    }
    const initialData = {
        supplier: '',
        date_of_purchase: '',
        bill_number: '',
        productDetail: [],
        purchase_file: '',
        remark: ''
    }
    const inputs = [
        {
            name: 'supplier',
            placeholder: 'Enter Customer Name',
            label: 'Supplier',
            type: 'text',
            element: 'select',
            required: true,
            optionValue: [
                { name: 'Supplier 1', value: '1' },
                { name: 'Supplier 2', value: '2' },
                { name: 'Supplier 3', value: '3' },
            ],
            errorMessage: 'This Field Cannot Be Empty !',
        },
        {
            name: 'date_of_purchase',
            placeholder: 'Enter Customer Name',
            label: 'Date of Purchase',
            type: 'date',
            element: 'input',
            required: true,
            errorMessage: 'This Field Cannot Be Empty !',
        },
        {
            name: 'bill_number',
            placeholder: 'Enter Bill Number',
            label: 'Bill Number',
            type: 'tel',
            element: 'input',
            required: false,
            errorMessage: '',
        },
    ]
    const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
    const handleOpenCreateCustomer = () => {
        setIsCreateDialogOpen(true)
    }
    const handleCloseDialog = () => {
        setIsCreateDialogOpen(false)
    }
    const { handleGetData,paramsObject,setGetListParams } = useGetDataFromServer('purchaseList', initialFilterData)
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_no: val.selected
        }
        setGetListParams(data)

     }
    return (
        <>
            <div className="py-4 border-t border-gray-300">
                <div className="flex items-center space-x-4">
                    <div className="flex-1">
                        <input type="text" className="input2" name="email" placeholder="Search Purchase..."></input>
                    </div>
                    <button className="btn3" onClick={handleOpenCreateCustomer}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        <span>Create Purchase</span>
                    </button>
                </div>
            </div>
            {handleGetData.isLoading ? <Spinner />
                : handleGetData.isError ? <Errors />
                    : <><div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Sr.no
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Product ID
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Product Name
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Product Quantity
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Status
                                    </th>
                                    {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Action
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {handleGetData?.data.data.map((list,listIdx) => {
                                    const {prod_id,prod_name,quantity} = list
                                    return  <tr key={listIdx}>
                                    <td className="border-dashed border-t border-gray-200 userId">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                            {(paramsObject.limit * (paramsObject.page_no + 1)) - (paramsObject.limit - (listIdx + 1))}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{prod_id}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{prod_name}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{quantity}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <select className="text-sm text-gray-700">
                                            <option value="">--Select Status--</option>
                                            <option value="ACTIVE">Active</option>
                                            <option value="SUSPENDED">Suspended</option>
                                            <option value="ADMIN_DISABLED">Admin disabled</option>
                                            <option value="DISABLED">Disabled</option>
                                        </select>
                                    </td>
                                    {/* <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                                            <span data-id='1'><EditIcon/></span>
                                            <DeleteIcon/>
                                        </span>
                                    </td> */}
                                </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className=" my-7">
                    <Pagination currentPage={paramsObject.page_no} pageCount={handleGetData?.data.total_count} limit={paramsObject.limit} onPageChange={handlePageClick}/>
                  </div>
                  </>
                    }
            <CreateForm fields={inputs} initialData={initialData} open={isCreatDialogOpen} onClose={handleCloseDialog} customerType={'Purchase'} />
        </>
    )
}

export default Purchase