import React, { useContext } from 'react'
import { Dialog, Slide } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import FormElement from '../../components/form/FormElement';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../components/spinner';
import AlertContext from '../../context/alerts';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AddSingleProduct({ open, onClose, onCreate }) {
    // const [textValue, setTextValue] = useState('');

    // // Event handler to update the textarea value
    // const handleChange = (event) => {
    //   setTextValue(event.target.value);
    // };
    const { sendAlert } = useContext(AlertContext)

    const getYearList = useGetDataFromServer("getYearList");
    const initialData = {
        product_name: '',
        category_id: '',
        rate: '',
        qr_prefix: '',
        opening_balance: '',
        year_id: '',
        belong: '',
        description :''

    }
    const [productFormData, setProductFormData] = useState(initialData)
    const { setDataToServer, error } = usePostDataToServer("/createProduct", "listProduct", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            sendAlert(err?.response?.data?.message, "ERROR")
            // console.log('error', err)
        }
    })
    const { handleGetData, paramsObject } = useGetDataFromServer("listCategory")

    const handleInputs = (e) => {
        let value = e.target.value
        let data = {
            ...productFormData,
            [e.target.name]: value
        }
        setProductFormData(data)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        // console.log(productFormData);
        setDataToServer.mutate(productFormData)
    }
    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        setProductFormData(initialData)
        onClose()
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }


    return (
        <Dialog fullScreen fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)} TransitionComponent={Transition}>
            <div className='container_xxl'>
                <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={handleClearAndCloseForm}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading capitalize text-2xl'>Add Product</h1>
                    </div>
                </div>
                <div className='form-body'>
                    <form className='py-7' onSubmit={(e) => handleSubmitForm(e)} autocomplete="off">
                        <div className='grid grid-cols-2 gap-x-6 gap-y-6'>
                            <div className="mt-1">
                                <FormElement
                                    label={"Product Name"}
                                    onChange={handleInputs}
                                    placeholder={'Product Name'}
                                    element={'input'}
                                    name={'product_name'}
                                    autocomplete={"off"}
                                    required={'required'}
                                    errorMessage={'This is a Required Field'} />
                            </div>
                            <div className='mt-px'>
                                <label className="block text-xl lato mb-px">Category Type</label>
                                <select required onChange={handleInputs} name="category_id"
                                    className="input">
                                    <option value="">--Select Type--</option>
                                    {handleGetData?.data?.data?.map((categoryDrop, id) => { return <option value={categoryDrop?.id}>{categoryDrop?.category_name}</option> })}
                                </select>
                            </div>
                            <div>
                                <label className="block text-xl lato mb-px">Year</label>
                                <select required onChange={handleInputs} name="year_id" className="input">
                                    <option value="">--Select Year--</option>
                                    {getYearList?.handleGetData?.data?.data?.map((categoryDrop, id) => {
                                        return (
                                            <option value={categoryDrop?.year_id}>
                                                {categoryDrop?.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div>
                                <FormElement
                                    label={"Rate of interest"}
                                    onChange={handleInputs}
                                    placeholder={'Rate Of Interest'}
                                    element={'input'}
                                    type={'number'}
                                    onWheel={numberInputOnWheelPreventChange}
                                    name={'rate'}
                                    autocomplete={"off"}
                                    step={".01"}
                                    required={'required'}
                                    errorMessage={'This is a Required Field'} />
                            </div>
                            <div>
                                <FormElement
                                    label={"Opening Balance"}
                                    onChange={handleInputs}
                                    placeholder={'Opening Balance'}
                                    element={'input'}
                                    name={'opening_balance'}
                                    type={'number'}
                                    autocomplete={"off"}
                                    onWheel={numberInputOnWheelPreventChange}
                                    required={'required'}
                                    errorMessage={'This is a Required Field'} />
                            </div>
                            <div className=''>
                                <label className="block text-xl lato mb-px">Belongs</label>
                                <select required onChange={handleInputs} name="belong"
                                    className="input">
                                    <option value="">--Select Type--</option>
                                    <option value="head office">Head Office</option>
                                    <option value="common">Common</option>
                                    <option value="school">School</option>
                                </select>
                            </div>
                            <div>
                                <FormElement
                                    label={"QR Prefix"}
                                    onChange={handleInputs}
                                    placeholder={'QR Prefix'}
                                    element={'input'}
                                    name={'qr_prefix'}
                                    autocomplete={"off"}
                                    required={'required'}
                                    errorMessage={'This is a Required Field'} />
                            </div>
                            <div>
                                {/* <FormElement
                                    label={"Description"}
                                    onChange={handleInputs}
                                    placeholder={'Description'}
                                    element={'input'}
                                    name={'description'}
                                    autocomplete={"off"} */}
                                    {/* // required={'required'}
                                    // errorMessage={'This is a Required Field'} 
                                    /> */}<h2 className="block text-xl lato mb-px"> Description</h2>
                                     <textarea
                                     
                                     className='input min-h-[10rem]' 
                                        value={productFormData.description}
                                        onChange={handleInputs}
                                        rows={4} // Set the number of visible rows
                                        cols={50} // Set the number of visible columns
                                        placeholder=" Description "
                                        name='description'
                                     />
                            </div>

                        </div>
                        {setDataToServer.isLoading ?
                            <Spinner /> :
                            <div className="flex justify-center mt-10">
                                <button type='submit' className='btn3 text-white'>Add Product</button>
                            </div>}
                    </form>
                    {error && <small className="text-red-700 font-semibold mt-1">{setDataToServer?.error?.response?.data?.message}</small>}
                </div>
            </div>
        </Dialog>
    )
}

export default AddSingleProduct