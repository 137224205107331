import React, { useState, useEffect, useContext } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import FormElement from '../../components/form/FormElement';
import Spinner from '../../components/spinner';
import AlertContext from '../../context/alerts';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function EditVendor({ open, onClose, singleData, onEdit }) {
    const { sendAlert } = useContext(AlertContext)
    const [vendorData, setVendorData] = useState(singleData);
    const [isChanged, setIsChanged] = useState(false)
    const { setDataToServer } = usePostDataToServer("/updateVendor", "listVendor", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const handleSubmitForm = (e) => {
        e.preventDefault()
        const initialData = {
            id: vendorData.id,
            vendor_name: vendorData?.name,
            vendor_email: vendorData.email,
            vendor_phone_number: vendorData.phone_number,
            address_one: vendorData.address_1,
            address_two: vendorData.address_2,
            city: vendorData.city,
            state: vendorData.state,
            pin_code: vendorData.pin_code,
            gst_no: vendorData.gst_no,
            company_name: vendorData.company_name,
            cp_name: vendorData.cp_name,
            cp_email: vendorData.cp_email,
            cp_number: vendorData.cp_number

        }
        setDataToServer.mutate(initialData)
    }

    const handleInputs = (e) => {
        let name = e.target.name
        let value = e.target.value
        let numRegex = new RegExp(/^[0-9]*$/)

        if (name === 'phone_number') {
            if (numRegex.test(value)) {
                let data = {
                    ...vendorData,
                    [e.target.name]: value
                }
                setVendorData(data)
            }

        } else {
            let data = {
                ...vendorData,
                [e.target.name]: value
            }
            setVendorData(data)
            setIsChanged(true)
        }

        // let value = e.target.value
        // let data = {
        //     ...vendorData,
        //     [e.target.name]: value
        // }
        // setVendorData(data)
    }

    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    return (<Dialog fullScreen fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)} TransitionComponent={Transition}>
        <div className='container_xxl'>
            <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                <div>
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div>
                    <h1 className='heading capitalize text-2xl'>Edit Vendor</h1>
                </div>
            </div>
            <form onSubmit={(e) => handleSubmitForm(e)}>
                <div className='grid grid-cols-3 gap-x-5 mt-5 gap-y-5'>
                    <FormElement
                        value={vendorData?.name}
                        onChange={handleInputs}
                        label={'Vendor Name'}
                        placeholder={'vendor name'}
                        autocomplete={"off"}
                        element={'input'}
                        name={'name'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        value={vendorData?.email}
                        onChange={handleInputs}
                        label={'Vendor Email'}
                        placeholder={'vendor@email.com'}
                        element={'input'}
                        autocomplete={"off"}
                        type={'email'}
                        name={'email'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        value={vendorData?.phone_number}
                        onChange={handleInputs}
                        label={'Contact No'}
                        placeholder={'contact no'}
                        autocomplete={"off"}
                        element={'input'}
                        maxlength={"10"}
                        name={'phone_number'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        value={vendorData?.address_1}
                        onChange={handleInputs}
                        label={'Address 1'}
                        autocomplete={"off"}
                        placeholder={'address'}
                        element={'input'}
                        name={'address_1'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        value={vendorData?.address_2}
                        onChange={handleInputs}
                        label={'Address 2'}
                        autocomplete={"off"}
                        placeholder={'address'}
                        element={'input'}
                        name={'address_2'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        value={vendorData?.state}
                        onChange={handleInputs}
                        label={'State'}
                        autocomplete={"off"}
                        placeholder={'state'}
                        element={'input'}
                        name={'state'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        value={vendorData?.city}
                        onChange={handleInputs}
                        label={'City'}
                        autocomplete={"off"}
                        placeholder={'City'}
                        element={'input'}
                        name={'city'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        value={vendorData?.pin_code}
                        onChange={handleInputs}
                        label={'Pincode'}
                        placeholder={'Pincode'}
                        type={'number'}
                        autocomplete={"off"}
                        onWheel={numberInputOnWheelPreventChange}
                        element={'input'}
                        name={'pin_code'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        value={vendorData?.gst_no}
                        onChange={handleInputs}
                        label={'GST No'}
                        autocomplete={"off"}
                        placeholder={'GST no'}
                        element={'input'}
                        name={'gst_no'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        value={vendorData?.company_name}
                        onChange={handleInputs}
                        label={'Company Name'}
                        autocomplete={"off"}
                        placeholder={'company name'}
                        element={'input'}
                        name={'company_name'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                </div>
                <div className='grid grid-cols-3 gap-x-5 gap-y-5 mt-12'>
                    <FormElement
                        value={vendorData?.cp_name}
                        onChange={handleInputs}
                        label={'Contact Person Name'}
                        placeholder={'contact person name'}
                        autocomplete={"off"}
                        element={'input'}
                        name={'cp_name'} />
                    <FormElement
                        value={vendorData?.cp_email}
                        onChange={handleInputs}
                        label={'Contact Person Email'}
                        placeholder={'cp@email.com'}
                        element={'input'}
                        autocomplete={"off"}
                        type={'email'}
                        name={'cp_email'} />
                    <FormElement
                        value={vendorData?.cp_number}
                        onChange={handleInputs}
                        label={'Contact Person Contact'}
                        placeholder={'contact person contact'}
                        maxlength={"10"}
                        autocomplete={"off"}
                        element={'input'}
                        type={'tel'}
                        name={'cp_number'} />
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> : isChanged == true ? <div className="flex justify-center mt-5">
                        <button type='submit' className='text-white btn3'>Edit</button>
                    </div> : <div className="flex justify-center mt-5">
                        <button disabled type='submit' className='text-white btn3 bg-blue-300'>Edit</button>
                    </div>}
            </form>
        </div>
    </Dialog>
    )
}
export default EditVendor;