import { useState } from 'react'
import axios from '../../apis/axios'
import { useMutation } from "@tanstack/react-query";
import Spinner from '../../components/spinner';
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from 'react-router-dom'
import { TOKEN_NAME } from '../../config'
import logo2 from "../../assets/images/logo2.png"

const cookies = new Cookies

function Login() {
  let token = cookies.get(TOKEN_NAME);
  let navigate = useNavigate()
  const [credError, setCredError] = useState(null)
  const handleAuth = useMutation((data) => handleFormSubmit(data), {
    onSuccess: (data) => {
      cookies.set('name', data.data.name)
      cookies.set(TOKEN_NAME, data.data.token, { path: '/' })
      navigate("/users", { replace: true });
    },
    onError: (err) => {
      if (err.response.status === 400) {
        return setCredError('Email or password is incorrect')
      } else {
        setCredError('Opps! Cannot Login At The Moment. Please Try Again Later')
      }
    }
  })

  const handleFormSubmit = async (getFormData) => {
    let fd = new FormData()
    for (var key in getFormData) {
      fd.append(key, getFormData[key]);
    }
    const response = await axios({
      method: 'POST',
      url: '/login',
      data: fd,
      // withCredentials: true,
    })
    return response
  }

  const handleAuthSubmit = (e) => {
    e.preventDefault()
    const data = Object.fromEntries(new FormData(e.target).entries());
    handleAuth.mutate(data)
  }
  if (token) return <Navigate to="/" replace />
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100">
        <div className="flex flex-col items-center justify-center w-full flex-1 px-20 ">
          <div className="bg-white rounded-2xl shadow-2xl flex w-2/3 max-w-4xl">
            <div className="w-3/5 py-20 px-9">
              <p className=" text-3xl font-semibold mb-4 lato">Login</p>
              <form className=" space-y-4" onSubmit={handleAuthSubmit}>
                <div>
                  <label htmlFor="email" className="label">Email</label>
                  <input type="email" autoComplete='username' required
                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm" name="email" placeholder="Enter your email here"></input>
                </div>
                <div>
                  <label htmlFor="password" className="label">Password</label>
                  <input type="password" required
                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                    name="password" autoComplete='current-password' placeholder="Enter your password here"></input>
                </div>
                {credError && <div className='text-red-600 text-center font-semibold'><small>{credError}</small></div>}
                <div className=" flex items-center">
                  <div className="forgetpassword">
                    {/* <Link to={`/forgot_password`} className="lato text-sm"><span>Forgot Password?</span></Link> */}
                  </div>
                </div>
                {handleAuth.isLoading ? <div className=' w-8 h-8 mx-auto'>
                  <Spinner />
                </div> : <div className="button">
                  <button type="submit" className=" w-full py-2 bg-[color:var(--primaryBlue)] text-white rounded lato">Login</button>
                </div>}
              </form>
            </div>
            <div className="w-2/5  text-white rounded-r-2xl rounded-br-2xl mt-20 px-12 text-center ">
              <img src={logo2} />
              {/* <p className="lato text-lg font-semibold text-[color:var(--color1)]">Welcome To <span className="font-extrabold">Avabai</span></p> */}
              <div className=" flex justify-center mt-8">
              </div>
            </div>
          </div>
        </div>
      </div>
    </>


  )
}

export default Login