import { useMutation } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import axios from '../apis/axios';
import { TOKEN_NAME } from "../config";
const cookies = new Cookies();

function useDownloadXlsxFile({ url, onError = () => { } }) {

    const reportDownload = useMutation((data) => downloadReports(data), {
        onSuccess: ({res,filename}) => {
            const data = res.data
            const href = URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.href = href;
            a.download = filename;
            a.click();
        },
        onError: async (e) => {
            const data = await e.response.data.text()
            onError(JSON.parse(data).message)
        }
    })

    const downloadReports = async (data) => {
        const filename = data.filename ? data.filename : 'Report.xlsx'
        let TOKEN = cookies.get(TOKEN_NAME);
        let fd = new FormData()
        for (var key in data) {
            fd.append(key, data[key]);
        }
        let res = await axios(
            {
                method: "post",
                url: url,
                responseType: 'blob',
                data: fd,
                headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '' }
            })
            
        return {res, filename}
    };


    return { reportDownload }
}

export default useDownloadXlsxFile