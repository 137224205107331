import React from 'react'
import Layout from '../../components/layout'
import TabNav from '../../components/TabNav'
import CategoryIcon from '@mui/icons-material/Category';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

function ProductLayout({ children }) {
    const navData = [
        { title: 'Single Product', slug: 'single-product', icon: <PrecisionManufacturingIcon /> },
        { title: 'Assembled Product', slug: 'assembled-product', icon: <CategoryIcon /> }
    ]
    return (
        <Layout>
            <div className="py-4">
                <TabNav navData={navData} />
            </div>
            {children}
        </Layout>
    )
}

export default ProductLayout