import React from 'react'
import { Navigate } from 'react-router-dom'
import Cookies from "universal-cookie";
import { TOKEN_NAME } from '../config';
const cookies = new Cookies();

function ProtectedRoutes({ component: Component, ...restOfProps }) {
    let token = cookies.get(TOKEN_NAME);
    const { children } = restOfProps
    if (!token) {
        return <Navigate to="/login" replace />;
    }else {
        return children;
    }
}

export default ProtectedRoutes
