import React, { useEffect, useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { checkIfEmpty } from '../../utility';
import FileUploadIcon from '@mui/icons-material/FileUpload';

function UploadFile({ onClose, open, setFiles, files, setSendFile }) {

    const inputFileRef = useRef(null);

    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }

    const handleSetFile = (e) => {
        let file = e.target.files
        Object.keys(file)?.forEach((f) => {
            setFiles(oldArray => [...oldArray, file[f]])
        })
    }

    const handleUploadFile = () => {
        inputFileRef.current.click();
    }
    const handleRemoveFile = (id) => {
        files.splice(id, 1)
        setFiles([...files])
    }


    return (<Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
        <div className='bg-white w-full px-5'>
            <div className='modal-head flex items-center justify-between'>
                <div className='mt-1.5'>
                    <h1 className='heading capitalize text-2xl'>Upload Purchase Bill</h1>
                </div>
                <div onClick={onClose}>
                    <IconButton>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        </div>
        <form className=''>
            <div className='flex justify-center py-7'>
                <button
                    type="button"
                    className="btn3 text-white"
                    onClick={handleUploadFile}><FileUploadIcon /> Upload </button>
                <input
                    ref={inputFileRef}
                    accept=".pdf,.xlsx"
                    style={{ display: "none" }}
                    multiple
                    type="file"
                    onChange={handleSetFile}
                />
            </div>
            <div className='ml-8 space-y-9'>
                {files <= 0 ? <p className='flex justify-center mr-9'>No Files </p> : files?.map((file, idx) => {
                    const { name } = file;
                    return <div key={idx} className="grid grid-cols-3 gap-x-9 mt-5 mb-4">
                        <p className='w-2'>{idx + 1}</p>
                        <p>{name}</p>
                        <div className="">
                            <button className="" type='button'><CloseIcon className="" onClick={() => handleRemoveFile(idx)} /></button>
                        </div>
                    </div>
                })}
            </div>
            <div className='flex justify-end mb-2 mr-2'>
            <button
                type="button"
                className="bg-[color:var(--primaryRed)] text-white px-6 py-1 rounded-lg"
                onClick={onClose}>Submit</button>
            </div>
        </form>
    </Dialog>);
}

export default UploadFile;