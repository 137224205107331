import React, { Component, useContext, useState } from 'react'
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import AlertContext from '../../context/alerts';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import FormElement from '../../components/form/FormElement';
import Spinner from '../../components/spinner';
import RegeneratePassword from './RegeneratePassword';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function EditUser({ open, onClose, singleData, onEdit }) {
    const { sendAlert } = useContext(AlertContext)
    const [userData, setUserData] = useState(singleData);

    const [openConfirm, setOpenConfirm] = useState(false)
    const [isChanged, setIsChanged] = useState(false)


    const { setDataToServer, error } = usePostDataToServer("/updateUser", "getUserList", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const handleInputs = (e) => {
        let name = e.target.name
        let value = e.target.value
        let numRegex = new RegExp(/^[0-9]*$/)

        if (name === 'phone_number') {
            if (numRegex.test(value)) {
                let data = {
                    ...userData,
                    [e.target.name]: value
                }
                setUserData(data)
            }
        } else {
            let data = {
                ...userData,
                [e.target.name]: value
            }
            setUserData(data)
        }
        setIsChanged(true)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        const initialData = {
            user_id: userData.user_id,
            first_name: userData?.first_name,
            email: userData.email,
            phone_number: userData.phone_number,
            last_name: userData.last_name,
            password: userData.password
        }
        setDataToServer.mutate(initialData)
    }

    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }

    const saveData = () => {
        setOpenConfirm(true)
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    return (<Dialog fullScreen fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)} TransitionComponent={Transition}>
        <div className='container_xxl'>
            <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                <div>
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div>
                    <h1 className='heading capitalize text-2xl'>Edit User</h1>
                </div>
            </div>
            <div className='mt-7'>
                <button className="btn3 text-white" onClick={saveData}>
                    <span className="text-sm">Regenerate Password</span>
                </button>
            </div>
            <form onSubmit={(e) => handleSubmitForm(e)} className="py-7">
                <div className='grid grid-cols-2 gap-x-5 mt-5 gap-y-5'>
                    <FormElement
                        label={'First Name'}
                        onChange={handleInputs}
                        value={userData?.first_name}
                        placeholder={'e.g.John'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'first_name'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        label={'Last Name'}
                        onChange={handleInputs}
                        value={userData?.last_name}
                        placeholder={'e.g.Doe'}
                        element={'input'}
                        name={'last_name'}
                        autocomplete={"off"}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        label={'Phone'}
                        onChange={handleInputs}
                        value={userData?.phone_number}
                        placeholder={'e.g.123456789'}
                        element={'input'}
                        name={'phone_number'}
                        autocomplete={"off"}
                        maxLength={'10'}
                        // onWheel={numberInputOnWheelPreventChange}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        label={'Email'}
                        onChange={handleInputs}
                        value={userData?.email}
                        placeholder={'e.g.admin@mail.com'}
                        element={'input'}
                        type={'email'}
                        autocomplete={"off"}
                        name={'email'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> : isChanged == true ?
                        <div className="flex justify-center mt-5">
                            <button type='submit' className='text-white btn3'>Edit</button>
                        </div> : <div className="flex justify-center mt-5">
                            <button disabled type='submit' className='text-white btn3 bg-blue-300'>Edit</button>
                        </div>}
                {error && <small className="text-red-700 text-base font-semibold mt-5 flex justify-center">{setDataToServer?.error?.response?.data?.message}</small>}
            </form>
        </div>
        {openConfirm && <RegeneratePassword open={openConfirm} onClose={() => setOpenConfirm(false)} userData={userData} />}
    </Dialog>
    );
}

export default EditUser;