import axios from "axios";
import Cookies from 'universal-cookie';
import { checkIfNotEmpty } from "../utility";
import {BASE_URL} from "./axios";
let cookies = new Cookies();


export const displayProductDetails = async (pageNo, search) => {
    const tokenData = cookies.get("token");
    let fd = new FormData();
    if (search !== null && search !== undefined && search !== "")
        fd.append("searchQuery", search);
    if (checkIfNotEmpty(pageNo)) {
        fd.append("pageNo", pageNo)
    }
    return await axios({
        method: "post",
        url: `${BASE_URL}/listProduct`,
        headers: { Authorization: `Bearer ${tokenData}` },
        data: fd
    })
}