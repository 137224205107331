import Navbar from "../navbar/Navbar"
import Sidebar from "../sidebar/Sidebar"

const Layout = ({ children }) => {
    return <div className="list home">
        <Sidebar />
        <div className="listContainer homeContainer">
            <Navbar />
            <div className="px-4">
                {children}
            </div>
        </div>
    </div>
}

export default Layout