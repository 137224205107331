import React, { useState, useEffect, useContext } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import FormElement from '../../components/form/FormElement';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../components/spinner';
import AlertContext from '../../context/alerts';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function AddCategoryType({ open, onClose, onCreate }) {
    const { sendAlert } = useContext(AlertContext)
    const initialData = {
        category_name: ''
    }
    const [categoryTypeData, setCategoryTypeData] = useState(initialData);
    const { setDataToServer, error } = usePostDataToServer("/createCategory", "listCategory", {
        onSuccessCb: (data) => {
            sendAlert(data.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const handleInputs = (e) => {
        let value = e.target.value
        let data = {
            ...categoryTypeData,
            [e.target.name]: value
        }
        setCategoryTypeData(data)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        setDataToServer.mutate(categoryTypeData)
    }
    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }
    return (<Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
        <div className='bg-white w-full px-5'>
            <div className='modal-head flex items-center justify-between'>
                <div className='mt-1.5'>
                    <h1 className='heading capitalize text-2xl'>Add Category Type</h1>
                </div>
                <div>
                    <div onClick={onClose}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <form onSubmit={(e) => handleSubmitForm(e)} className="py-7">
                <div className='flex justify-center mt-5 space-x-48'>
                    <FormElement
                        label={'Category Type'}
                        onChange={handleInputs}
                        placeholder={'e.g. Furniture'}
                        autocomplete={"off"}
                        element={'input'}
                        name={'category_name'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> : <div className="flex justify-center mt-5">
                        <button type='submit' className='btn3 text-white'>Create</button>
                    </div>}
            </form>
            {error && <small className="text-red-700 font-semibold mt-1">{setDataToServer?.error?.response?.data?.message}</small>}
        </div>
    </Dialog>
    )
}
export default AddCategoryType;