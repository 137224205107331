import axios from 'axios';

//const BASE_URL = 'http://192.168.1.43';
export const BASE_URL = 'https://sapi.avabaipetitschool.org';

export default axios.create({
    baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
