import React, { useContext, useState } from 'react'
import DeleteItem from '../../components/DeleteItem/DeleteItem';
import Layout from '../../components/layout';
import Pagination from '../../components/pagination';
import Spinner from '../../components/spinner';
import AlertContext from '../../context/alerts';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import { checkIfEmpty, checkIfNotEmpty } from '../../utility';
import Errors from '../errors';
import SubmitModal from './SubmitModal';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ConfirmationModal from './ConfirmationModal';


function ProductAssets() {

    const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const [openConfirm, setOpenConfirm] = useState(false)

    const [submitData, setSubmitData] = useState()


    const getYearList = useGetDataFromServer("getYearList");
    const listCategory = useGetDataFromServer("listCategory");

    const [yearData, setYearData] = useState({
        year_id: "",
        pageNo: 0,
        limit: 10,
        searchQuery: '',
        category_id: ""
    });

    const { sendAlert } = useContext(AlertContext)

    const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer("calculateClosingAmount", yearData, !!yearData?.year_id && !!yearData?.category_id)

    const { setDataToServer } = usePostDataToServer("/saveProductAssessment", "calculateClosingAmount", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })


    const handleOpenSubmit = (e) => {
        setIsCreateDialogOpen(true)
    }


    const handleCloseDialog = () => {
        setIsCreateDialogOpen(false)
    }

    const handleInputs = (e) => {
        let value = e.target.value;
        let data = {
            ...yearData,
            [e.target.name]: value,
        };
        setYearData(data);
        setGetListParams(data);
    };

    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }

    const [deleteData, setDeleteData] = useState()
    const deleteAssetData = () => {
        let data = {
            year_id: yearData?.year_id,
            category_id: yearData?.category_id
        }
        setDeleteData(data)
        setOpenDeleteModal(true)
    }

    const onDeleteSuccess = (data) => {
        sendAlert(data?.message, "SUCCESS")
    }

    const onSubmitSuccess = (data) => {
        sendAlert(data?.message, "SUCCESS")
    }

    const onDeleteError = (data) => {
        sendAlert(data?.response?.data?.message, "ERROR")
    }

    const onSubmitError = (data) => {
        sendAlert(data?.response?.data?.message, "ERROR")
    }

    const saveData = () => {
        let data = {
            year_id: yearData?.year_id,
            category_id: yearData?.category_id,
            product_data: JSON.stringify(handleGetData?.data?.data)
        }
        setSubmitData(data)
        setOpenConfirm(true)
    }
    // console.log('handleGetData.error.response.data.message :>> ', handleGetData?.error?.response?.data?.message);

    return (<Layout>
        {checkIfNotEmpty(yearData?.year_id) && checkIfNotEmpty(yearData?.category_id) ? <div className="flex justify-end mt-5">
            <button onClick={(e) => handleOpenSubmit(e)} className="bg-[color:var(--primaryRed)] text-white h-10 w-44 rounded-lg">
                <FileDownloadIcon /> <span>Report</span>
            </button>
        </div> : ""}

        <div>
            <h1 className='text-center heading capitalize text-3xl'>Assets</h1>
        </div>


        <div className='flex space-x-20 justify-center'>
            <div className="flex flex-col items-center mt-5">
                <label className="block text-xl lato mb-5">
                    Please Select a Year from the Dropdown
                </label>
                <select required onChange={handleInputs} name="year_id" className="border-2 border-grey w-96 h-12 rounded-md">
                    <option value="">--Select Year--</option>
                    {getYearList?.handleGetData?.data?.data?.map((categoryDrop, id) => {
                        return (
                            <option value={categoryDrop?.year_id}>
                                {categoryDrop?.name}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="flex flex-col items-center mt-5">
                <label className="block text-xl lato mb-5">Please Select a Category from the Dropdown</label>
                <select onChange={handleInputs} name="category_id"
                    className="border-2 border-grey w-96 h-12 rounded-md">
                    <option value="">--Select Type--</option>
                    {listCategory?.handleGetData?.data?.data?.map((categoryDrop, id) => { return <option value={categoryDrop?.id}>{categoryDrop?.category_name}</option> })}
                </select>
            </div>

        </div>
        {handleGetData.isLoading && checkIfNotEmpty(yearData?.year_id) && checkIfNotEmpty(yearData?.category_id) ? <Spinner /> : ""}
        {handleGetData.isError
            ? handleGetData?.error?.response?.data?.message?.startsWith("No data found")
                ? <div className="flex items-center justify-center h-96">
                    <p className="text-lg font-semibold">No Data Available</p>
                </div>
                : <Errors errorObject={handleGetData?.error} inline />
            :
            ""
        }
        {checkIfNotEmpty(handleGetData?.data?.data) && handleGetData?.data?.data != 0 ? <>
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead>
                        <tr className="text-center">
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-sm">
                                Opening
                            </th>
                            <th colspan='2' className="bg-gray-100 sticky top-0 border-b border-r  border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-sm">
                                Addition
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-sm">
                                Deduction
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-sm">

                            </th>
                            <th colspan='2' className="bg-gray-100 sticky top-0 border-r border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-sm">
                                Depreciation
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-sm">
                                W.D.V
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-100 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr className="text-center">
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                sr.no
                            </th>
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider text-xs uppercase">
                                Product
                            </th>
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider text-xs uppercase">
                                Category
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-3 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Balance As.on
                            </th>
                            <th colspan='2' className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                During this year
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                During
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-sm">
                                Total
                            </th>
                            <th colspan='2' className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                For The Year
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                As on
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-sm">
                                Belongs
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr className="text-center">
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider text-xs">

                            </th>
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                            <th rowspan='4' className="bg-gray-100 sticky top-0 border-r border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                REF
                            </th>
                            <th className="bg-gray-100 border-r sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                {"> 180 Days"}
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                {"< 180 Days"}
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                The Year
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                RATE(%)
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                AMT
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                REF
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>

                        </tr>
                    </thead>
                    <thead>
                        <tr className="text-center">
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                            <th className="bg-gray-100 sticky border-r top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider text-xs">

                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-normal tracking-wider capitalize text-xs">
                                (RS.)
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-normal tracking-wider capitalize text-xs">
                                (RS.)
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-normal tracking-wider capitalize text-xs">
                                (RS.)
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-normal tracking-wider capitalize text-xs">
                                (RS.)
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-normal tracking-wider capitalize text-xs">
                                (RS.)
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-normal tracking-wider capitalize text-xs">
                                (RS.)
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-normal tracking-wider capitalize text-xs">
                                (RS.)
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-r border-gray-200 px-6 py-2 text-gray-600 font-normal tracking-wider capitalize text-xs">
                                (RS.)
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {handleGetData?.data?.data?.map((data, idx) => {
                            return <tr key={idx}>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm sticky">
                                        {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm sticky">
                                        {data?.product}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm sticky">
                                        {data?.category_name}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {data?.opening_bal}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {data?.addition_after_six_month}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {data?.addition_before_six_month}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {data?.total_deduction}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {data?.total}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {data?.rate}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {Math.trunc(data?.depreciation_value)}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {data?.closing_bal}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-5 py-3 flex items-center text-sm capitalize">
                                        {data?.belong}
                                    </span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {handleGetData.data.current_year == 'true' ? <div className='flex justify-end mt-10'>
                {handleGetData.data.isSet === true ? <button className="btn3 text-white bg-[color:var(--primaryRed)]" onClick={deleteAssetData}>
                    <span className="text-sm">Delete</span>
                </button> : <button className="btn3 text-white" onClick={saveData}>
                    <span className="text-sm">Submit</span>
                </button>}
            </div> : ""}
            {/* <div className="my-7">
                <Pagination currentPage={paramsObject?.pageNo} pageCount={handleGetData?.data?.total_count} limit={paramsObject.limit} onPageChange={handlePageClick} />
            </div> */}
        </> : ''}
        <SubmitModal open={isCreatDialogOpen} onClose={handleCloseDialog} handleGetData={handleGetData} />
        {openDeleteModal && <DeleteItem onDelete={onDeleteSuccess} onError={onDeleteError} open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} multiParam={deleteData} url={"deleteProductAssessmentData"} queryKey={"calculateClosingAmount"} />}
        {openConfirm && <ConfirmationModal content={"Are you sure you want to set the closing balance for current year and update the opening balance for next year?"} multiParam={submitData} onSubmit={onSubmitSuccess} onError={onSubmitError} open={openConfirm} onClose={() => setOpenConfirm(false)} url={'/saveProductAssessment'} queryKey={"calculateClosingAmount"} />}

    </Layout>);
}

export default ProductAssets;