import React, { useState, useEffect, useContext, useRef, createRef } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import FormElement from '../../components/form/FormElement';
import CloseIcon from '@mui/icons-material/Close';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import AlertContext from "../../context/alerts";
import Spinner from '../../components/spinner';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import UploadFile from './UploadFile';
import { AsyncPaginate } from 'react-select-async-paginate';
import { displayProductDetails } from '../../apis/productlistapi';
import { checkIfNotEmpty } from '../../utility';
import { displayVendorDetails } from '../../apis/vendorListApi';
import useGetVendorDropdown from '../../hooks/useGetVendorDropdown';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CreatePurchaseProduct({ open, onClose }) {
    const initialData = {
        product_id: '',
        quantity: '',
        perpiece_price: '',
        total_amount: '',
        vendor_id: '',
    }

    const { sendAlert } = useContext(AlertContext)
    const [productFormData, setProductFormData] = useState(initialData)
    const [value, setValue] = useState()
    const [sendFile, setSendFile] = useState([])
    const [files, setFiles] = useState([]);
    const [openUpload, setOpenUpoad] = useState()
    const [startDate, setStartDate] = useState(new Date());
    const { handleGetData } = useGetDataFromServer("listProduct")
    // const listVendor = useGetDataFromServer("listVendor")

    const { setDataToServer, error } = usePostDataToServer("/createPurchaseProduct", "listPurchaseProduct", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })
    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }
    const handleInputs = (e) => {
        let value = e.target.value
        let data = {
            ...productFormData,
            [e.target.name]: value,
        }
        if (e.target.name !== 'total_amount') {
            let newcount = data.perpiece_price * data.quantity
            data.total_amount = newcount
        }
        setProductFormData(data)
    }

    const saveData = () => {
        setOpenUpoad(true)
    }






    // Scroll select code 
    const [roomDropName, setRoomName] = useState([])
    const [pageNoBuilding, setPageNoBuilding] = useState(0);
    const [previousState, setPreviousState] = useState("");

    const initialLoadForEffect = useRef(true);
    const initialLoadBuilding = useRef(false)

    const formatData = (arr, valueName, labelName) => {
        let newarr = []
        for (let i = 0; i < arr?.length; i++) {
            newarr.push({ value: arr[i]?.[valueName], label: arr[i]?.[labelName], ...arr[i] })
        }
        return newarr;
    }

    const getProductName = async () => {
        const res = await displayProductDetails();
        const res2 = formatData(res.data.data, 'product_id', 'product_name')
        setRoomName(res2);
    }

    useEffect(() => { 
        if (initialLoadForEffect.current) {
            getProductName();
            initialLoadForEffect.current = false;
        }
    }, [])

    const loadBuildingNames = (search, prevOptions) => {
        const formatDataFn = (resp) => {
            return formatData(resp, 'product_id', 'product_name')
        }
        return loadOptions(search, prevOptions,
            initialLoadBuilding,
            roomDropName, setRoomName,
            pageNoBuilding, setPageNoBuilding, formatDataFn)
    }

    const loadOptions = async (search, prevOptions, initialLoad, state, setState, pageNo, setPageNo, formatDataFn) => {
        try {
            if (!initialLoad.current && !search) {
                initialLoad.current = true;
                return {
                    options: state,
                    hasMore: true
                };
            }
            else {
                let newPage;
                if (checkIfNotEmpty(search) && previousState !== search) {
                    newPage = 0;
                    setPageNo(newPage);
                } else {
                    // newPage = pageNo;
                    newPage = pageNo + 1;
                    setPageNo(newPage);
                }

                let res = await displayProductDetails(newPage, search)
                newPage = pageNo + 1;
                setPageNo(newPage);

                const data = state.concat(res?.data?.data)
                setState(data)
                let temps = res.data.data;

                let hasMore = true;
                if (res?.data?.data?.length == 0) {
                    hasMore = false;
                }
                let arr = formatDataFn(res?.data?.data);
                setPreviousState(search)

                return {
                    options: arr,
                    hasMore: hasMore
                };
            }
        }
        catch (err) {
            // console.log(err)
        }
    }

    const handleProductName = (e) => {
        let data = {
            ...productFormData,
            product_id: e.product_id
        }
        setProductFormData(data)
    }

    // End here

    // Async scroll for vendor  
    const { vendorValue, loadVendorNames} = useGetVendorDropdown();

    const handleVendorChange = (e) => {
        let data = {
            ...productFormData,
            vendor_id: e.id,
        }

        setProductFormData(data);
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        let newDate = new Date(startDate).toDateString()
        let data = {
            product_id: productFormData.product_id,
            quantity: productFormData.quantity,
            perpiece_price: productFormData.perpiece_price,
            total_amount: productFormData.total_amount + "",
            vendor_id: productFormData.vendor_id,
            date: newDate,
            "bill[]": files
        }
        setDataToServer.mutate(data)
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }



    return (<Dialog fullScreen fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)} TransitionComponent={Transition}>
        <div className='container_xxl'>
            <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                <div>
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div>
                    <h1 className='heading capitalize text-2xl'>Create Purchase Product</h1>
                </div>
            </div>
            <form className='py-10' onSubmit={(e) => handleSubmitForm(e)}>
                <div className='grid grid-cols-3 gap-x-9 gap-y-6'>
                    <div className=''>
                        <label className="block text-xl lato mb-px">Product</label>
                        {/* <select required onChange={handleInputs} name="product_id"
                            className="border-2 border-grey w-full h-12 rounded-md">
                            <option value="">--Select Type--</option>
                            {handleGetData?.data?.data?.map((categoryDrop, id) => { return <option value={categoryDrop?.product_id}>{categoryDrop?.product_name}</option> })}
                        </select> */}
                        <AsyncPaginate
                            value={roomDropName && roomDropName?.product_id}
                            // options={buildingName}
                            isSearchable={true}
                            lab
                            loadOptions={loadBuildingNames}
                            onChange={(e) => handleProductName(e)}
                        />
                    </div>
                    <div className=''>
                        <label className="block text-xl lato mb-px">Vendor</label>
                        <AsyncPaginate
                            value={vendorValue && vendorValue?.id}
                            isSearchable={true}
                            lab
                            loadOptions={loadVendorNames}
                            onChange={(e) => handleVendorChange(e)}
                        />
                    </div>
                    <div>
                        <label className='label'>Date</label>
                        <DatePicker required
                            // className="px-3 py-1 w-60 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded"
                            className='input mt-1'
                            selected={startDate} onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>

                    <div className="mt-1">
                        <FormElement
                            label={"Quantity"}
                            onChange={handleInputs}
                            placeholder={'Quantity'}
                            element={'input'}
                            type={'number'}
                            autocomplete={"off"}
                            onWheel={numberInputOnWheelPreventChange}
                            name={'quantity'}
                            required={'required'}
                            errorMessage={'This is a Required Field'} />
                    </div>
                    <div className="mt-1">
                        <FormElement
                            label={"Per-piece Price"}
                            onChange={handleInputs}
                            placeholder={'Per-piece Price'}
                            element={'input'}
                            autocomplete={"off"}
                            type={'number'}
                            onWheel={numberInputOnWheelPreventChange}
                            name={'perpiece_price'}
                            required={'required'}
                            errorMessage={'This is a Required Field'} />
                    </div>
                    <div className="mt-1">
                        <FormElement
                            label={"Total Amount"}
                            onChange={handleInputs}
                            placeholder={'Total Amount'}
                            element={'input'}
                            autocomplete={"off"}
                            name={'total_amount'}
                            required={'required'}
                            value={productFormData?.total_amount}
                            errorMessage={'This is a Required Field'} />
                    </div>
                    <div className='mt-7'>
                        <button type="button" className="btn3 text-white" onClick={saveData}>
                            <span className="text-sm">Upload Bill</span>
                        </button>
                    </div>
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> :
                    <div className="flex justify-center mt-10">
                        <button type='submit' className='btn3 text-white'>Create Purchase</button>
                    </div>}
            </form>
            {openUpload && <UploadFile open={openUpload} onClose={() => setOpenUpoad(false)} setFiles={setFiles} files={files} setSendFile={setSendFile} />}
        </div>
    </Dialog>);
}

export default CreatePurchaseProduct;