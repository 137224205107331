import Cookies from "universal-cookie";
import { checkIfNotEmpty } from "../utility";
import { BASE_URL } from "./axios";
import axios from "axios";

let cookies = new Cookies();

export const displayVendorDetails = async (pageNo, search) => {
    const tokenData = cookies.get("token");
    let fd = new FormData();
    if (search !== null && search !== undefined && search !== "")
        fd.append("searchQuery", search);
    if (checkIfNotEmpty(pageNo)) {
        fd.append("pageNo", pageNo)
    }
    return await axios({
        method: "post",
        url: `${BASE_URL}/listVendor`,
        headers: { Authorization: `Bearer ${tokenData}` },
        data: fd
    })
}