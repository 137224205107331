import ReactPaginate from 'react-paginate';
import './paginate.css'
function Pagination({pageCount, onPageChange,limit,currentPage}) {
    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={onPageChange}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(pageCount/limit)}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName='flex items-center justify-center space-x-4'
            pageLinkClassName='pageNumber'
            previousLinkClassName='pageNumber'
            nextLinkClassName='pageNumber'
            activeLinkClassName='selectedPageNumber'
            disabledClassName='lastPage'
            disabledLinkClassName='lastPage'
            initialPage={currentPage}
        />
    )
}

export default Pagination
