import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import Pagination from '../pagination';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import useDownloadFile from '../../hooks/useDownloadFile';
import Spinner from '../spinner';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { checkIfEmpty } from '../../utility';
import spinner from "../../assets/images/spinner.gif"
import deBounce from '../../hooks/useDebounce';


function ProductQrModal({ open, onClose, singleData }) {
    let productId = {
        product_id: singleData?.product_id,
        pageNo: 0,
        per_page: 10,
        searchQuery: ''
    }
    const [qrList, setQrList] = useState([])

    const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer("getQrCodeList", productId)
    const { setDataToServer } = usePostDataToServer("/generateQrcodePdf")

    const [checked, setChecked] = useState([]);
    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    // const DownloadQr = (e) => {
    //     e.preventDefault()
    //     let data = {
    //         inventory_id: JSON.stringify(checked)
    //     }
    //     setDataToServer.mutate(data)
    // }

    const { reportDownload } = useDownloadFile()
    const handleDownloadReport = (e) => {
        e.preventDefault()
        let data = {
            inventory_id: JSON.stringify(checked)
        }
        reportDownload.mutate(data)
    }

    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }

    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            [e.target.name]: value
        }
        deBounce(setGetListParams, data)
        // setGetListParams(data)
    }
    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }


    return (<Dialog maxWidth={'md'} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
        <div className='bg-white w-full px-5'>
            <div className='modal-head flex items-center justify-between'>
                <div className='mt-1.5'>
                    <h1 className='heading capitalize text-2xl'>Download Product QR</h1>
                </div>
                <div>
                    {/* {reportDownload.isLoading ? <Spinner /> : ""} */}
                </div>
                <div className='flex space-x-5'>
                    {checked.length <= 0 ? "" : reportDownload.isLoading ? <div className='w-12 h-12'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className='flex justify-end mt-1'>
                        <button onClick={(e) => handleDownloadReport(e)} className='w-12 h-9 bg-[color:var(--primaryRed)] text-white'><FileDownloadIcon />{checked.length}</button>
                    </div>}
                    <div onClick={onClose}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <form>
                <div className="flex-1">
                    <input type="text" autocomplete="off" className="input2" name="searchQuery" placeholder="Search Product..." value={paramsObject.search_query} onChange={handleSearch}></input>
                </div>
                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                    {handleGetData.isLoading ? <div className='flex justify-center'><Spinner /></div> : handleGetData?.data?.data <= 0 || checkIfEmpty(handleGetData?.data?.data) ? <div className="flex items-center justify-center h-96">
                        <p className="text-lg font-semibold">No Data Available</p>
                    </div> : <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                        <thead>
                            <tr className="text-left">
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    #
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Qr Code
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {handleGetData?.data?.data?.map((list, idx) => {
                                return <tr key={idx} >
                                    <td className="border-dashed border-t border-gray-200 userId">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                            <input className="mr-4" type="checkbox" onChange={(e) => handleCheck(e)} value={list?.inventory_id} />
                                            {(paramsObject.per_page * (paramsObject.pageNo + 1)) - (paramsObject.per_page - (idx + 1))}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 userId">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                            {list?.qr_code}
                                        </span>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>}
                </div>
            </form>
        </div>
        <div className="my-7">
            <Pagination
                currentPage={paramsObject.pageNo}
                pageCount={handleGetData?.data?.total_count}
                limit={paramsObject.per_page}
                onPageChange={handlePageClick} />
        </div>
    </Dialog >);
}

export default ProductQrModal;