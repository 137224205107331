import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InventoryIcon from '@mui/icons-material/Inventory';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CategoryIcon from '@mui/icons-material/Category';
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { TOKEN_NAME } from "../../config";
import logo2 from "../../assets/images/logo2.png"
import EventIcon from '@mui/icons-material/Event';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';

const cookies = new Cookies


// const cssActiveLink = 'space-x-3 bg-[color:var(--primaryBlue)] text-[color:var(--primaryWhite)]'
// const cssUnActiveLink = 'hover:bg-[color:var(--color2)] hover:text-[color:var(--primaryWhite)]'

const cssActiveLink = 'space-x-3 mr-2 bg-[color:var(--primaryRed)] text-[color:var(--primaryWhite)]'
const cssUnActiveLink = 'mr-2 hover:bg-[color:var(--color2)] hover:text-[color:var(--primaryBlack)]'


const Sidebar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    cookies.remove(TOKEN_NAME)
    cookies.remove('name')
    navigate('/login', { replace: true, })
  }
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/users" style={{ textDecoration: "none" }}>
          <div className="w-14 h-14">
            <img src={logo2} />
          </div>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          {/* <p className="title">MAIN</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link> */}
          {/* <p className="title">USERS</p> */}


          <div className="text-sm roboto text-center py-1.5">
            <p className="mt-5" />
            <NavLink to={"/users"} className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
              <PersonIcon className="icon pl-1" />
              <span>Users</span>
            </NavLink>
          </div>


          <div className="text-sm roboto text-center py-1.5">
            <NavLink to={"/rooms"} className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
              <MeetingRoomIcon className="icon pl-1" />
              <span>Rooms</span>
            </NavLink>
          </div>


          <div className="text-sm roboto text-center py-1.5">
            <NavLink to="/infrastructure" className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>

              <ApartmentIcon className="icon pl-1" />
              <span>Infrastructure</span>

            </NavLink>
          </div>

          <div className="text-sm roboto text-center py-1.5">
            <NavLink to="/year" className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>

              <CalendarTodayIcon className="icon pl-1" />
              <span>Year</span>

            </NavLink>
          </div>

          <hr className="mt-3" />
          <div className="text-sm roboto text-center py-1.5">
            <NavLink to="/category" className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>

              <CategoryIcon className="icon pl-1" />
              <span>Category</span>

            </NavLink>
          </div>

          <div className="text-sm roboto text-center py-1.5">
            <NavLink to="/single-product" className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>

              <InventoryIcon className="icon pl-1" />
              <span>Products</span>

            </NavLink>
          </div>

          <div className="text-sm roboto text-center py-1.5">
            <NavLink to="/stats" className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>

              <EventIcon className="icon pl-1" />
              <span>Stats</span>

            </NavLink>
          </div>

          <div className="text-sm roboto text-center py-1.5">
            <NavLink to="/assets" className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>

              <InventoryIcon className="icon pl-1" />
              <span>Assets</span>

            </NavLink>
          </div>


          <hr className="mt-3" />
          <div className="text-sm roboto text-center py-1.5">
            <NavLink to="/vendor" className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>

              <PersonOutlineIcon className="icon pl-1" />
              <span>Vendors</span>

            </NavLink>
          </div>

          <div className="text-sm roboto text-center py-1.5">
            <NavLink to="/purchase" className={({ isActive }) => `flex items-center p-1.5 rounded space-x-3 ${isActive ? cssActiveLink : cssUnActiveLink}`}>
              <AccountBalanceWalletIcon className="icon pl-1" />
              <span>Billing</span>
            </NavLink>
          </div>


          <div className="text-sm roboto p-1.5 space-x-3">
            <ExitToAppIcon className="icon pl-1" />
            <span className="cursor-pointer" onClick={handleLogout}>Logout</span>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
